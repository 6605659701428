import * as mathUtils from "./math";
import * as runModelUtils from "./runModel";
import { authenticator } from "./authenticate";
import { downloadFile } from "@/api/models/image-enhancer.js";

const getSessionsSource = function () {
  return (
    sessionStorage.getItem("utm_source") ||
    sessionStorage.getItem("ref") ||
    "organic"
  );
};

const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};

//download image by url
const downloadImageFromUrl = (src) => {
  fetch(src)
    .then(response => {
      return response.blob();
    })
    .then(blob => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Download.png';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    })
};
// download video by url
const downloadVideoFromUrl = (src) => {
  var link = document.createElement("a");
  link.href = src;
  link.download = "Download.mp4";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
//download image by url
const getImageUrlByPreviewId = async (previewId) => {
  try {
    const res = await downloadFile({ previewId: previewId });
    if (res.code === 0) {
      return res.data;
    }
  } catch (error) {
    console.log("[ error ] >", error);
  }
};

const getFileFromUrl = async (src) => {
  const response = await fetch(src);
  const file = new File([await response.blob()], "image.png", {
    type: "image/png",
  });
  return file;
};

const isLoggedIn = () => {
  return false;
};

const isOnlyEnglish = (value) => {
  const patt = /^[\x00-\x7F]*$/;

  const onlyEnglish = patt.test(value);
  return onlyEnglish;
};

const cancelReq = (apiUrls) => {
  if (window.axiosPromiseArr) {
    window.axiosPromiseArr.forEach((ele, ind) => {
      if (apiUrls.includes(ele.url)) {
        ele.cancel();
      }

      delete window.axiosPromiseArr[ind];
    });
  }
};

const getVideoInfoFromFile = async (file) => {
  return new Promise((resolve, reject) => {
    var video = document.createElement("video");
    video.preload = "metadata";
    let info = {};
    video.src = URL.createObjectURL(file);
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      info.duration = Math.ceil(video.duration);
      resolve(info);
    };
  });
};

async function loadImage (url) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.onload = () => resolve(image);
    image.onerror = (error) => reject(error);
  });
}



// 获取素材宽高
const getMaterialResolution = (file, callback) => {

  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    let width = 0
    let height = 0
    reader.readAsDataURL(file)
    reader.onload = function (e) {
      if (file.type.includes('image/')) { // 如果是图片，获取图片宽高
        const image = new Image()
        image.src = file['url']
        image.onload = function () {
          width = image.width
          height = image.height
          console.log('width, height:', width, height)
          resolve({ width, height })
          // callback(width, height)
        }
      } else if (file.type.includes('video/')) { // 如果是视频，获取视频的宽高和时长，只能获取到H264及以上视频编码协议（MPEG-4编码格式也获取不到）
        const videoUrl = URL.createObjectURL(file)
        const videoObj = document.createElement('video')
        videoObj.preload = 'metadata'
        videoObj.src = videoUrl

        videoObj.onloadedmetadata = function (evt) {
          URL.revokeObjectURL(videoUrl)
          width = videoObj.videoWidth
          height = videoObj.videoHeight
          console.log('width, height:', width, height)
          resolve({ width, height })
          // callback(width, height)
        }
      } else {
        reject()
      }
    }
  })

}

export {
  mathUtils,
  runModelUtils,
  getSessionsSource,
  getDeviceType,
  downloadImageFromUrl,
  downloadVideoFromUrl,
  getFileFromUrl,
  getImageUrlByPreviewId,
  isLoggedIn,
  isOnlyEnglish,
  cancelReq,
  getVideoInfoFromFile,
  loadImage,
  authenticator,
  getMaterialResolution
};
